import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Eureka" />
    <h3 className='underline-title'>Eureka</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Mon eureka n'est pas celui du grec Archimède<br />
        Mon eureka est plus meurtrier que l'arme de la guerre des étoiles<br />
      </p>
      <p>
        D'ailleurs les vraies étoiles ne sont jamais guerrières<br />
        En revanche les fausses étoiles sont belliqueuses<br />
        <span className="par-starting"></span>les réganesques évidemment <br />
      </p>
      <p>
        Les fausses étoiles ajoutées à mon eureka<br />
        Nous pouvons valablement nous défendre et vaincre l'énnemi? <br />
      </p>
      <p>
        Allo, allo terriens! allo allo terrestres!<br />
        Nous sommes assaillis! <br />
        <span className="par-starting"></span>ne perdons pas notre temps à nous menacer <br />
        <span className="par-starting"></span>unissons-nous habitants armés de la terre <br />
      </p>
      <p>
        Notez:<br />
        attaque surprise d'une violence singulière à Tambora/Indonésie: 92.000 morts<br />
        agression sans ultimatum à St. Helens/Washington: 3.000 victimes<br />
        Offensive récente entre 70° à 80° longitue Ouest et 0° à 10° latitude nord:<br />
        <span className="par-starting"></span>innombrables vies humaines<br />
        assaut plusieurs fois Pearl Harbor '41 explosa sur Nevada<br />
        <span className="par-starting"></span>en Colombie d'Alfonso Lopes: 22.000 tués<br />
      </p>
      <p>
        En avant compatriotes armés de la Terre<br />
        Défendons-nous contre-attaquons l'ennemi de notre planète<br />
      </p>
      <p>
        Vanités!<br />
        Rabbouni - "aucunes armes forgées contre Toi ne réussiront"<br />
        Vous êtes Tout-Puissant<br />
      </p>
      <p>
        Le-Tout-Puissant<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
